import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Page } from '../entities/page';
import { Purchase } from '../entities/purchase';
import { AuthService } from './auth/auth.service';
import { HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class PurchaseService {
  inspectorPurchasesUrl = environment.apiUrl + '/inspector/purchases/paginated';
  inspectorAvailablePurchasesUrl =
    environment.apiUrl + '/inspector/purchases/available_paginated';
  inspectorAcceptedPurchasesUrl =
    environment.apiUrl + '/inspector/purchases/accepted_paginated';
  acceptInspectionUrl =
    environment.apiUrl + '/inspector/purchases/accept_inspection';
  confirmInspectionUrl =
    environment.apiUrl + '/inspector/purchases/confirm_inspection';
  completeInspectionUrl =
    environment.apiUrl + '/inspector/purchases/complete_inspection';
  requestCorrectionUrl =
    environment.apiUrl + '/inspector/purchases/request_correction';
  update_paidPurchaseUrl =
    environment.apiUrl + '/inspector/purchases/update_paidPurchase';
  inspectorUrl = environment.apiUrl + '/inspector/purchases';
  clientPurchaseUrl = environment.apiUrl + '/client/purchases';

  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  async getPurchaseById(purchaseId: string): Promise<Purchase | void> {
    return firstValueFrom(
      this.http.get<Purchase>(`${this.inspectorUrl}/purchase/${purchaseId}`)
    ).catch((error) => {
      throw error;
    });
  }

  async getPurchases(
    userId?: string,
    sort?: string,
    order?: string,
    page: number = 1,
    limit: number = 0,
    search?: string
  ): Promise<Page<Purchase[]>> {
    const { itemCount, pages, purchases } = await firstValueFrom(
      this.http.get<{ itemCount: number; pages: any[]; purchases: Purchase[] }>(
        `${this.inspectorPurchasesUrl}?userId=${userId}&page=${page}&limit=${limit}&sort=${sort}&order=${order}&search=${search}`
      )
    ).catch((error) => {
      throw error;
    });
    return new Page<Purchase[]>(itemCount, pages, purchases);
  }

  async getAvailablePurchases(
    userId?: string,
    sort?: string,
    order?: string,
    page: number = 1,
    limit: number = 0,
    search?: string
  ): Promise<Page<Purchase[]>> {
    const { itemCount, pages, purchases } = await firstValueFrom(
      this.http.get<{ itemCount: number; pages: any[]; purchases: Purchase[] }>(
        `${this.inspectorAvailablePurchasesUrl}?userId=${userId}&page=${page}&limit=${limit}&sort=${sort}&order=${order}&search=${search}`
      )
    ).catch((error) => {
      throw error;
    });
    return new Page<Purchase[]>(itemCount, pages, purchases);
  }

  async getAcceptedPurchases(
    userId?: string,
    sort?: string,
    order?: string,
    page: number = 1,
    limit: number = 0,
    search?: string
  ): Promise<Page<Purchase[]>> {
    const { itemCount, pages, purchases } = await firstValueFrom(
      this.http.get<{ itemCount: number; pages: any[]; purchases: Purchase[] }>(
        `${this.inspectorAcceptedPurchasesUrl}?userId=${userId}&page=${page}&limit=${limit}&sort=${sort}&order=${order}&search=${search}`
      )
    ).catch((error) => {
      throw error;
    });
    return new Page<Purchase[]>(itemCount, pages, purchases);
  }

  async acceptInspection(purchaseId: string, inspectorId: string) {
    await firstValueFrom(
      this.http.get(
        this.acceptInspectionUrl +
          '?inspectorId=' +
          inspectorId +
          '&purchaseId=' +
          purchaseId
      )
    ).catch((error) => {
      throw error;
    });
  }

  async confirmInspection(
    purchaseId: string,
    date: string,
    time: string,
    reason?: string,
    customerLang?: string
  ) {
    if (reason && customerLang) {
      const currentLang = this.translate.currentLang;
      this.translate.use(customerLang);
      const translatedReason = this.translate.instant(
        'client.inspections.mandate_page.modifiedDateReason.' + reason
      );
      if (
        translatedReason !==
        'client.inspections.mandate_page.modifiedDateReason.' + reason
      ) {
        reason = translatedReason;
      }
      this.translate.use(currentLang);
    }

    await firstValueFrom(
      this.http.get(
        `${this.confirmInspectionUrl}?purchaseId=${purchaseId}&date=${date}&time=${time}&reason=${reason}`
      )
    ).catch((error) => {
      throw error;
    });
  }

  //called when an inspection is completed, purchase status is set to complete and recieves the inspection ID.
  async completePurchase(inspectionId: string) {
    await firstValueFrom(
      this.http.get(
        this.completeInspectionUrl + '?inspectionId=' + inspectionId
      )
    ).catch((error) => {
      throw error;
    });
  }

  // signals that the inspection needs a correction
  async requestCorrection(inspectionId: string, correctionDescription: string) {
    await firstValueFrom(
      this.http.patch(
        this.requestCorrectionUrl + '?inspectionId=' + inspectionId,
        { correctionDescription }
      )
    ).catch((error) => {
      throw error;
    });
  }

  async updateInspectionId(purchaseId: string, inspectionId: string) {
    await firstValueFrom(
      this.http.patch(this.inspectorUrl + '/updateInspectionId', {
        purchaseId,
        inspectionId,
      })
    ).catch((error) => {
      throw error;
    });
  }

  async cancelPurchaseStatus(purchase_id: string) {
    await firstValueFrom(
      this.http.get(
        `${this.inspectorUrl}/cancelStatus?purchaseId=${purchase_id}`
      )
    ).catch((error) => {
      throw error;
    });
  }

  async reportProblem(purchase_id: string, problemMessage: string) {
    await firstValueFrom(
      this.http.get(
        `${this.inspectorUrl}/reportProblem?purchaseId=${purchase_id}&problemMessage=${problemMessage}`
      )
    ).catch((error) => {
      throw error;
    });
  }

  async addLead(lead: any) {
    return firstValueFrom(
      this.http.post<any>(this.clientPurchaseUrl + '/ceateLead', {
        data: lead,
      })
    );
  }

  async updateLead(lead: any) {
    return firstValueFrom(
      this.http.put<any>(this.clientPurchaseUrl + '/updateLead', {
        data: lead,
      })
    );
  }

  async verifyBusinessPassword(password: string) {
    const body = JSON.stringify({ password: password });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return await firstValueFrom(
      this.http.post(`${this.clientPurchaseUrl}/verifyBusinessPassword`, body, {
        headers: headers,
      })
    ).catch((error) => {
      throw error;
    });
  }

  async getPurchaseWithInspectionId(inspectionId: string): Promise<Purchase> {
    return await firstValueFrom(
      this.http.get<Purchase>(
        `${this.clientPurchaseUrl}/inspectionId/${inspectionId}`
      )
    ).catch((error) => {
      throw error;
    });
  }
  async updateFormFilledStatus(
    purchaseId: string,
    status: boolean
  ): Promise<any> {
    return firstValueFrom(
      this.http.put(
        `${this.clientPurchaseUrl + '/updateFormFilledStatus'}/${purchaseId}`,
        {
          status: status,
        }
      )
    ).catch((error) => {
      throw error;
    });
  }

  async getPaidNotCompletedPurchase(purchaseId: string): Promise<Purchase> {
    return firstValueFrom(
      this.http.get<Purchase>(
        `${
          this.clientPurchaseUrl + '/getPaidNotCompletedPurchase'
        }/${purchaseId}`
      )
    ).catch((error) => {
      throw error;
    });
  }
  async update_paidPurchase(purchase: any): Promise<Purchase | void> {
    return firstValueFrom(
      this.http.post<Purchase>(this.update_paidPurchaseUrl, purchase)
    ).catch((error) => {
      throw error;
    });
  }
}
